import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import BirthdayPartiesSlider from '../components/pages/BirthdayPartiesSlider';
import { Link } from 'gatsby';
import '../styles/app.scss';

const Classes = ({ data }) => {
    const { wordpressPage: post } = data;

    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section smallestwdt respitesec">
                <div
                    className="container"
                    dangerouslySetInnerHTML={{
                        __html: post.content,
                    }}
                />
            </section>
            <div
                id="myCarousel9"
                className="carousel slide"
                data-ride="carousel"
                style={{ maxWidth: '840px' }}
            >
                <BirthdayPartiesSlider
                    galleryImages={post.acf.classes_gallery}
                />
            </div>
            <h2 className="bluetxt" style={{ textAlign: 'center', paddingTop: '40px', paddingBottom: '40px' }} >
                Interested in a Group Class?{'   '}
                <Link
                    to={`https://form.jotform.com/andreahughiern/wrtsfranklingroupclassinterestform`}
                    className="wrtsbtn yellowbtn"
                    style={{
                        display: 'inline-block',
                        width: '200px',
                        fontSize: '26px',
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Click Here
                </Link>
            </h2>
            <Footer />
        </>
    );
};
export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            content
            slug
            acf {
                classes_gallery {
                    id
                    localFile {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;

export default Classes;
